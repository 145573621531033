import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import Icon from '../../layout/icons';
import Container from '../../components/container';

import i18n from '../../i18n';

const PrevNextBlog = ({ next, prev }) => (
  <Wrapper>
    <Container>
      <NextPrev>
        {prev && (
          <StyledLink to={prev.frontmatter?.slug}>
            <StyledIcon prev icon="chevron-left" />
            <div>
              <Title>
                <span className="large-title">{prev.frontmatter?.title}</span>
                <span className="small-title">{i18n('prev', 'Vorige')}</span>
              </Title>
              <Date>{prev.frontmatter?.date}</Date>
            </div>
          </StyledLink>
        )}
      </NextPrev>
      <NextPrev next>
        {next && (
          <StyledLink to={next.frontmatter?.slug}>
            <div>
              <Title>
                <span className="large-title">{next.frontmatter?.title}</span>
                <span className="small-title">{i18n('next', 'Volgende')}</span>
              </Title>
              <Date>{next.frontmatter?.date}</Date>
            </div>
            <StyledIcon next icon="chevron-right" />
          </StyledLink>
        )}
      </NextPrev>
    </Container>
  </Wrapper>
);

const Title = styled.span`
  color: ${(props) => props.theme.fontColor};
  .large-title {
   display: none;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    .large-title {
      display: block;
    }
    .small-title {
      display: none;
    }
  }
`;
const Date = styled.span`
  display: none;
  color: ${(props) => props.theme.gray3};
  font-size: .8rem;
  padding-top: .5rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: block;
  }
`;
const StyledIcon = styled(Icon)`
  width: 1rem;
  color: ${(props) => props.theme.fontColor};
  padding: 1rem .5rem;
  background-color: ${(props) => props.theme.gray1};
  ${(props) => props.next && css`
    margin-left: 1rem;
  `}
  ${(props) => props.prev && css`
    margin-right: 1rem;
  `}
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;
const NextPrev = styled.div`
  flex: 1;
  display: flex;
  ${(props) => props.next && css`
    justify-content: flex-end;
  `}
`;
const Wrapper = styled.div`
  background-color: ${(props) => props.theme.gray1};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    background-color: transparent;
  }
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default styled(PrevNextBlog)``;
