import React from 'react';
import styled from 'styled-components';


export default function OrderedList({ items = [] }) {
  return (
    <List>
      {items.map((item, index) => {
        if (item.link) {
          return (
            <ListItem key={`${item.text}-${index}`}>
              <Anchor href={item.link}>{item.text}</Anchor>
            </ListItem>
          );
        }
        return (
          <ListItem key={`${item.text}-${index}`}>{item.text}</ListItem>
        );
      })}
    </List>
  );
}

export const Anchor = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.fontColor};
`;

const ListItem = styled.li`
  position:relative;
  margin-left: 60px;
  margin-bottom: 5px;
  padding:5px 0;
  // width:50px;
  height:40px;
  
  display:flex;
  align-items: center;
    
  &:before {
    display:flex;
    align-items: center;
    justify-content: center;
    content: counter(mycounter) "."; 
    counter-increment:mycounter;
    position:absolute;
    top:0;
    left:-60px;
    width:50px;
    height:50px;
    line-height:25px;
    background:#f2f5f7;
    text-align:center;
  }
`;

const List = styled.ol`
  list-style:none;
  counter-reset:mycounter;
  padding:0;
`;
