import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Layout from '../layout';
import Seo from '../components/seo';
import Container from '../components/container';
import BlockMapping from '../blocks/block-mapping';

import BlogIntro from './partials/blog-intro';
import PrevNextBlog from './partials/prev-next-blog';
import OrderedList, { Anchor } from '../components/OrderedList';
import Socials from '../layout/footer/socials';
import { GridIcon, GridTitle, StyledLinkText } from '../layout/header/nav/DropdownList';
import { CheckList, StyledIcon as CheckListIcon } from '../blocks/pricing-plans/pricing-plan';
import CtaButton from '../layout/header/CtaButton';
import SharePostBar from './partials/share-post-bar';
// import Form from '../blocks/form/form';
// import Button from '../components/button';

export const BlogTemplate = (props) => {
  const { blocks = [] } = props;
  const hosting = [{
    title: 'Magento hosting',
    description: 'Geoptimaliseerd voor Magento',
    slug: '/magento-hosting/',
    icon: '/img/magento_menu_icon.svg',
    iconAlt: 'Magento icon',
  }, {
    title: 'Shopware hosting',
    description: 'Giga snelle Shopware hosting',
    slug: '/shopware-hosting/',
    icon: '/img/shopware_menu_icon.svg',
    iconAlt: 'Shopware icon',
  }, {
    title: 'Akeneo hosting',
    description: 'Veilig, betrouwbaar en snel',
    slug: '/akeneo-hosting/',
    icon: '/img/akeneo_menu_icon.svg',
    iconAlt: 'Akeneo icon',
  }, {
    title: 'Pimcore hosting',
    description: 'Pijlsnelle Pimcore hosting',
    slug: '/pimcore-hosting/',
    icon: '/img/pimcore_menu_icon.svg',
    iconAlt: 'Pimcore icon',
  }, {
    title: 'PHP Webshop hosting',
    description: 'Hypersnelle php hosting',
    slug: '/php-hosting/',
    icon: '/img/php_menu_icon.svg',
    iconAlt: 'PHP icon',
  }, {
    title: 'Sylius hosting',
    description: 'Geoptimaliseerd voor Sylius',
    slug: '/sylius-hosting/',
    icon: '/img/sylius_menu_icon.svg',
    iconAlt: 'Sylius icon',
  }, {
    title: 'Drupal hosting',
    description: 'Supersnelle Drupal hosting',
    slug: '/drupal-hosting/',
    icon: '/img/drupal_menu_icon.svg',
    iconAlt: 'Drupal icon',
  }, {
    title: 'PrestaShop hosting',
    description: 'Geoptimaliseerd voor PrestaShop',
    slug: '/prestashop-hosting/',
    icon: '/img/prestashop_menu_icon.svg',
    iconAlt: 'PrestaShop icon',
  }, {
    title: 'WooCommerce hosting',
    description: 'Managed WooCommerce hosting',
    slug: '/woocommerce-hosting/',
    icon: '/img/woocommerce_menu_icon.svg',
    iconAlt: 'WooCommerce icon',
  }];

  return (
    <section>
      <Container>
        <BlogContent>
          <BlockMapping blocks={blocks} size={2} {...props}>
            <BlogIntro {...props} />
          </BlockMapping>
          <Column>
            <div>
              <H3>Top 5 meest gelezen</H3>
              <OrderedList
                items={[
                  {
                    text: 'Cloudflare: wat is het? En wat kun je ermee?',
                    link: '/cloudflare/',
                  },
                  {
                    text: 'Wat is SSH?',
                    link: '/wat-is-ssh/',
                  },
                  {
                    text: '7 dingen die je moet weten als je Shopware wilt gebruiken',
                    link: '/shopware-gebruiken/',
                  },
                  {
                    text: 'Shopware vs Magento: een vergelijking',
                    link: '/shopware-vs-magento/',
                  },
                  {
                    text: 'Wat is Time To First Byte (TTFB) en hoe verbeter je deze?',
                    link: '/time-to-first-byte-ttfb/',
                  },
                ]}
              />
            </div>
            <br />

            <div>
              <H3>Volg ons op social media</H3>
              <Socials sidebar />
            </div>
            <br />
            <div>
              <H3>Hypersnelle e-commerce hosting</H3>
              <br />
              <HostingGrid>
                {
                  hosting.map((host) => (
                    <>
                      <HostingGridIconWarpper>
                        <GridIcon src={host.icon} alt={host.iconAlt} size={35} />
                      </HostingGridIconWarpper>
                      <Anchor href={host.slug}>
                        <StyledLinkText light>
                          <GridTitle>{host.title}</GridTitle>
                        </StyledLinkText>
                      </Anchor>
                    </>
                  ))
                }
              </HostingGrid>
            </div>

            <br />
            <br />

            <Sticky>
              <H3>Snellere website = betere conversie</H3>
              <p>Probeer onze hypersnelle hosting 30 dagen gratis uit en ontvang een
                vergelijkingsrapport met je huidige hosting.
              </p>
              <br />
              <CheckList>
                <li>
                  <CheckListIcon withGradient icon="checkmark" />
                  Tot 30 dagen gratis uitproberen
                </li>
              </CheckList>
              <CheckList>
                <li>
                  <CheckListIcon withGradient icon="checkmark" />
                  Performance analyse
                </li>
              </CheckList>
              <CheckList>
                <li>
                  <CheckListIcon withGradient icon="checkmark" />
                  Vergelijkingsrapport huidige hosting
                </li>
              </CheckList>
              <CheckList>
                <li>
                  <CheckListIcon withGradient icon="checkmark" />
                  Beste hosting van Nederland
                </li>
              </CheckList>

              <br />

              <CtaButton />

              {/* <Form form={{ */}
              {/*  frontmatter: { */}
              {/*    endpoint: '', */}
              {/*    successMessage: 'Bedankt voor je bericht. We zullen zo snel mogelijk contact opnemen.', */}
              {/*    title: 'Contact me', */}
              {/*    name: 'blog-sidebar-contact', */}
              {/*    anchorId: 'blog-sidebar-contact', */}
              {/*    formFields: [ */}
              {/*      { */}
              {/*        name: 'email', */}
              {/*        type: 'email', */}
              {/*        required: true, */}
              {/*        placeholder: 'Uw email', */}
              {/*      }, */}
              {/*    ], */}
              {/*    buttonText: 'Gratis Uitproberen', */}
              {/*  }, */}
              {/* }} */}
              {/* /> */}

            </Sticky>
          </Column>
        </BlogContent>
        <SharePostBar {...props} />
      </Container>
    </section>
  );
};

const Blog = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <Seo {...frontmatter?.meta} title={frontmatter.title} slug={frontmatter.slug} />
      <Helmet>
        <meta name="author" content={frontmatter?.author?.frontmatter?.authorName} />
      </Helmet>
      <Wrapper>
        <BlogTemplate
          {...frontmatter}
        />
        <PrevNextBlog next={data.next} prev={data.prev} />
      </Wrapper>
    </Layout>
  );
};

const H3 = styled.h3`
  font-family: Airbnb Cereal App Medium;
`;

const HostingGrid = styled.div`
  display: grid;
  grid-template-columns: .75fr 6fr;
  grid-template-rows: repeat(4, .75fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
`;

const HostingGridIconWarpper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 1rem;
  min-width:200px;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-left: 3rem;
    margin-top: 0;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 2rem;
`;

const BlogContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

const Sticky = styled.div`
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
`;

export default Blog;

export const pageQuery = graphql`
  query BlogTemplate($id: String, $nextId: String, $prevId: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slug
        title
        author {
          frontmatter {
            authorName
          }
        }
        ...MetaTags
        ...BlogIntroPartial
        ...BlocksMapping
      }
    }
    next: markdownRemark(id: { eq: $nextId }) {
      frontmatter {
        slug
        title
        date(
          formatString: "D MMMM YYYY"
          locale: "nl-NL"
        )
      }
    }
    prev: markdownRemark(id: { eq: $prevId }) {
      frontmatter {
        slug
        title
        date(
          formatString: "D MMMM YYYY"
          locale: "nl-NL"
        )
      }
    }
  }
`;
