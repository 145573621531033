import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';
import { StyledIcon } from '../../blocks/block-mapping';
import { useSiteMetadata } from '../../context/siteMetadataContext';

function SharePost({ slug, className }) {
  const { siteUrl } = useSiteMetadata();
  const fullUrl = `${siteUrl}${slug}`;

  return (
    <div className={className}>
      <hr />
      <Meta>
        <ShareText>Deel dit artikel</ShareText>

        <IconWrapper
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${fullUrl}`}
          color="#3b5998"
        >
          <StyledIcon icon="facebook" />
        </IconWrapper>

        <IconWrapper
          target="_blank"
          href={`https://twitter.com/home?status=${fullUrl}`}
          color="#1da1f2"
        >
          <StyledIcon icon="twitter" />
        </IconWrapper>

        <IconWrapper
          target="_blank"
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${fullUrl}`}
          color="#0a66c2"
        >
          <StyledIcon icon="linkedin" />
        </IconWrapper>

      </Meta>
      <hr />
    </div>
  );
}

const ShareText = styled.p`
  margin-right: 1rem;
`;

const IconWrapper = styled(OutboundLink)`
  background-color: ${(props) => props.color};
  padding: .2rem 1.5rem;
  margin-right: .5rem;
`;

const Meta = styled.div`
  padding: .5rem 1rem;
  color: ${(props) => props.theme.gray3};
  display: flex;
  align-items: center;
`;

const SharePostBar = styled(SharePost)`
  padding-bottom: 3rem;
`;

export default SharePostBar;
