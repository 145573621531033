import React from 'react';
import { graphql, Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';

import Icon from '../../layout/icons';
import Container from '../../components/container';
import PreviewCompatibleImage from '../../components/preview-compatible-image';

import { useSiteMetadata } from '../../context/siteMetadataContext';

import i18n from '../../i18n';

export const query = graphql`
  fragment BlogIntroPartial on MarkdownRemarkFrontmatter {
    featuredBlogImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    title
    date(
      formatString: "D MMMM YYYY"
      locale: "nl-NL"
    )
    author {
      frontmatter {
        authorName
      }
    }
    categories {
      frontmatter {
        categoryTitle
        slug
      }
    }
  }
`;

const BlogIntro = ({
  featuredBlogImage,
  title,
  categories,
}) => {
  const { newsPath } = useSiteMetadata();
  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Breadcrumbs>
          <li>
            <Link to={`${newsPath}/`}>{i18n('blogLinkTitle', 'Nieuws & informatie')}</Link>
          </li>
          {(categories || []).slice(0, 1)
            .map(({ frontmatter }) => frontmatter && (
              <li key={frontmatter.slug}>
                <Link to={frontmatter.slug}>{frontmatter.categoryTitle}</Link>
              </li>
            ))}
          <li>{title}</li>
        </Breadcrumbs>
      </Container>
      {featuredBlogImage && (
        <ImageWrapper>
          <StyledImage image={featuredBlogImage} alt={title} />
        </ImageWrapper>
      )}
    </>
  );
};

export const StyledIcon = styled(Icon)`
  height: ${(props) => props.height || '.7rem'};
`;

export const IconWrapper = styled(OutboundLink)`
  border: 2px solid ${(props) => props.theme.gray3};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 1.1rem;
  width: 1.1rem;
  color: ${(props) => props.color || props.theme.gray3};
  margin-left: .5rem;
  display: none;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: flex;
  }
  &:hover {
    color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};
  }
`;

const Title = styled.h1`
  font-size: 2.2rem;
  line-height: 1;
  margin: 0 0 2rem 0;
  font-family: ${(props) => props.theme.fontFamilyMedium};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    font-size: 2.5rem;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 2rem;
`;

const StyledImage = styled(PreviewCompatibleImage)`
  max-width: 44.44rem;
  margin: 0 auto;
`;

const Breadcrumbs = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0 0 2rem 0;
  li {
    display: inline-block;
    color: ${(props) => props.theme.gray3};

    &:not(:last-child):after {
      content: " »";
      padding-right: .5rem;
    }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: ${(props) => props.theme.primaryColor};
      }
    }
  }
`;

export default styled(BlogIntro)`

`;
